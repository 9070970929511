var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c("gov-heading", { attrs: { size: "l" } }, [
                _vm._v(
                  _vm._s(_vm._f("ucfirst")(_vm.service.type)) + " locations"
                )
              ])
            ],
            1
          ),
          _vm.auth.isServiceAdmin(_vm.service.id)
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third text-right" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: {
                          name: "service-locations-create",
                          params: { service: _vm.service.id }
                        },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("\n        Add location\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("ck-service-locations-table", {
        attrs: { "service-locations": _vm.service.service_locations }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }